import React, { useState, useEffect, useRef } from 'react';
import '../../styles/Flashcard.css';

const Flashcard = () => {
  const [isVisible, setIsVisible] = useState(false);
  const flashcardRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      const lastShown = localStorage.getItem('flashcardLastShown');
      if (!lastShown || (Date.now() - lastShown > 120000)) { // 1 hour in milliseconds
        setIsVisible(true);
      }
    }, 3000); // Show after 5 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (flashcardRef.current && !flashcardRef.current.contains(event.target)) {
        setIsVisible(false);
        localStorage.setItem('flashcardLastShown', Date.now());
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  return (
    <div ref={flashcardRef} className={`flashcard ${isVisible ? 'open' : 'closed'}`}>
      <h2>Chat with an AI</h2>
      <p>Ask anything and receive human-like responses.</p>
      <a href="https://ajithjoseph.com/AI/ChatBot" rel="noopener noreferrer">
        Chat Now
      </a>
    </div>
  );
};

export default Flashcard;
