/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Ajith Joseph</h2>
        <p><a href="mailto:contact@ajithjoseph.com">contact@ajithjoseph.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I'm Ajith Joseph. I'm a full-stack developer with over 10 years of experience in the tech industry. I specialise in building and deploying web applications with tools such as React, Azure, ASP.NET, C#, MVC, Razor Pages, and .NET Core. My expertise includes creating RESTful APIs, managing databases, and designing modern user interfaces. I'm dedicated to writing clear, user-friendly code, which has consistently contributed to delivering high-quality products.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Ajith&apos;Joseph <Link to="/">ajithjoseph.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
