import React from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Analytics from '../components/Template/Analytics';
import Navigation from '../components/Template/Navigation';
import SideBar from '../components/Template/SideBar';
import ScrollToTop from '../components/Template/ScrollToTop';

const Main = (props) => (
  <HelmetProvider>
    <Analytics />
    <ScrollToTop />
    <Helmet
      titleTemplate="%s | Ajith Joseph - Full Stack Developer"
      defaultTitle="Ajith Joseph - Full Stack Developer"
      defer={false}
    >
      {props.title && <title>{props.title}</title>}
      <meta name="description" content={props.description} />
      <meta name="keywords" content="Ajith Joseph, Full Stack Developer, React, Azure, ASP.NET, C#, MVC, Razor Pages, .NET Core, RESTful APIs, Databases, UI/UX Design, Software Engineer, Bengaluru, India" />
      <meta name="author" content="Ajith Joseph" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="canonical" href="https://ajithjoseph.com/" />
      <meta property="og:image" content="https://ajithjoseph.com/images/me.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/jpeg" />

      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'WebSite',
              '@id': 'https://ajithjoseph.com/#website',
              url: 'https://ajithjoseph.com',
              name: 'Ajith Joseph - Full Stack Developer',
              publisher: {
                '@type': 'Person',
                name: 'Ajith Joseph',
                url: 'https://ajithjoseph.com',
                image: {
                  '@type': 'ImageObject',
                  url: 'https://ajithjoseph.com/images/me.jpg',
                  width: 200,
                },
              },
              copyrightHolder: {
                '@type': 'Person',
                name: 'Ajith Joseph',
              },
              sameAs: [
                'https://www.linkedin.com/in/ajithjozef/',
                'https://github.com/codzz',
                'https://www.facebook.com/ajithjozef',
                'https://www.instagram.com/joseph__ajith/',
                'https://angel.co/u/ajith-joseph-4',
                'https://twitter.com/ajithjosef',
              ],
            },
            {
              '@type': 'WebPage',
              headline: props.title || 'Ajith Joseph - Full Stack Developer | Portfolio & Projects',
              description: props.description || 'Ajith Joseph is a highly experienced Full Stack Developer with over 10 years of expertise in React, Azure, ASP.NET, C#, MVC, Razor Pages, and .NET Core. Skilled in designing RESTful APIs, managing databases, and creating modern user interfaces.',
              inLanguage: 'en-US',
              '@id': 'https://ajithjoseph.com/#webpage',
              url: 'https://ajithjoseph.com/',
              name: 'Ajith Joseph - Full Stack Developer',
              isPartOf: {
                '@type': 'WebSite',
                '@id': 'https://ajithjoseph.com/#website',
              },
            },
          ],
        })}
      </script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-5K2Q8M47EY" />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-5K2Q8M47EY'); 
        `}
      </script>
      <script type="text/javascript">
        {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/576tpvydaf";
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script");
        `}
      </script>
    </Helmet>
    <div id="wrapper">
      <Navigation />
      <div id="main">
        {props.children}
      </div>
      {props.fullPage ? null : <SideBar />}
    </div>
  </HelmetProvider>
);

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullPage: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

Main.defaultProps = {
  children: null,
  fullPage: false,
  title: null,
  description: "Ajith Joseph's personal website. I am a highly experienced Full Stack Developer with 10+ years of expertise in React, Azure, ASP.NET, C#, MVC, Razor Pages, and .NET Core. Skilled in designing RESTful APIs, managing databases, and creating modern user interfaces.",
};

export default Main;
