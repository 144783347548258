const routes = [
  {
    index: true,
    label: 'Ajith Joseph',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'API',
    path: '/API',
  },
  {
    label: 'AI ChatBot',
    path: '/AI/ChatBot',
  },
  {
    label: 'AI Collections',
    path: '/AI/all',
  },
];

export default routes;
